/*
 * @Author: your name
 * @Date: 2022-02-24 11:42:12
 * @LastEditTime: 2022-04-14 11:02:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\social\components\api.js
 */
import axios from '@/config/httpConfig'

//获取form的分享信息
export function getShareInfo(data) {
    return axios.post('/form/getShareInfo', data)
}
//删除Social帖子信息
export function delPostInfo(data) {
    return axios.post('/social/delPostsInfo', data)
}
//查询Social帖子详情
export function getPostInfoDetail(data) {
    return axios.post('/social/getPostsInfo', data)
}
// 批量删除帖子
export function delBatchPost(data) {
    return axios.post('/social/delBatchPosts', data)
}

// 查询Social用户信息
export function getScoialUserinfo(data) {
    return axios.post('/social/querySocialUser', data)
}
// 查询市场活动信息
export function getScoialMarketingActivity(data) {
    return axios.post('/social/queryCampaign', data)
}
//修改Social帖子
export function editPostInfo(data) {
    return axios.post('/social/editPostsInfo', data)
}

// 新发送Social帖子接口
export function savePostsInfo(data) {
    return axios.post('/social/savePostsInfo', data)
}
// 批量修改市场活动
export function updateBatchPostCampaign(data) {
    return axios.post('/social/updateBatchPostsCampaign', data)
}
// 查询Social上传的文件列表
export function querySocialFileInfo(data) {
    return axios.post('/social/querySocialFileInfo', data)
}
// 上传文件
export function socialUploadFile(data) {
    return axios.post('/social/socialUpload', data, {
        //设置请求头
        headers: { "Content-Type": "multipart/form-data" },
    })
}

// 获取form的列表信息
export function getFormList(data) {
    return axios.post('/form/getFormList', data)
}
//获取领英授权url
export function authorizationUrl(data) {
    return axios.post('/social/authorizationUrl', data)
}
//保存社交媒体用户和授权token
export function storeCacheAccessToken(data) {
    return axios.post('/social/storeCacheAccessToken', data)
}
//获取用户列表
export function getUserList() {
    return axios.post('approval/getUserList')
}
//修改帖子类型（定时和草稿转换）
export function editPoststype(data) {
    return axios.post('/social/editPoststype', data)
}
//查询集成应用linkedIn，facebook，twitter是否配置
export function getAppISConfig(data) {
    return axios.post('/social/getAppISConfig', data)
}