<!--  -->
<template>
  <div class="top">
    <div class="content">
      <div class="headerTop">
        <div class="header_box">
          <div class="mainTitle">Landing Pages</div>
          <div class="bt">
            <el-button
              size="small"
              type="primary"
              @click="
                () => {
                  this.dialogCreateVisible = true;
                }
              "
              >Create landing page</el-button
            >
          </div>
        </div>
      </div>
      <div class="primary">
        <div class="aside">
          <div class="select_title">Domain：</div>
          <el-dropdown
            class="select_box"
            placement="bottom-start"
            @command="handleCommand"
          >
            <div>
              <span class="el-dropdown-link">{{ selectName }}</span
              ><i class="el-icon-caret-bottom el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in domainList"
                :key="item.id"
                :command="item"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div class="pages_box">All Landing pages</div>
          <div class="tips_box">
            <div
              class="optionTips"
              v-for="(item, index) in tipList"
              @click="tipChecked(item.value)"
              :class="tipsValue === item.value ? 'curli' : ''"
              :key="index"
            >
              <div class="round_img" :style="`background:${item.color}`"></div>
              <span class="ml-10">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="main-content">
          <!-- 搜索 筛选-->
          <div class="bodynav">
            <div class="bodynavleft">
              <el-input
                class="search-text"
                v-model="searchValue"
                placeholder="Search for forms"
                @keyup.enter.native="searchList"
                suffix-icon="el-icon-search"
              ></el-input>
              <div class="Daterange">
                Campaign：
                <el-dropdown
                  @command="handleCommand"
                  trigger="click"
                  placement="bottom-start"
                >
                  <div>
                    <span class="el-dropdown-link">{{ campaignText }} </span>
                    <i class="el-icon-caret-bottom el-icon--right"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <div class="postingBox">
                      <div class="postingInput">
                        <el-input
                          placeholder=""
                          size="mini"
                          suffix-icon="el-icon-search"
                          @keyup.enter.native="searchPostMethod"
                          v-model="searchPost"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div class="postingCheck">
                      <div v-for="(item, index) in checkList" :key="index">
                        <el-checkbox v-model="item.checked">{{
                          item.mes
                        }}</el-checkbox>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div>
              <svg class="icon" aria-hidden="true">
                <use href="#icon-setcou"></use>
              </svg>
              <span @click="dialogVisible = true" class="manage_title"
                >Manage columns</span
              >
            </div>
          </div>
          <listTable
            :tableAttr="tableAttr"
            :tableData="tableData"
            :tableheight="tableheight"
            :selectvalue="selectvalue"
            :buttonlist="buttonlist"
            :pageshow="pageshow"
            :checkDisable="checkDisable"
            @showDrawer="showDrawer"
            @share="share"
            @unpublished="unpublished"
            @achieve="achieve"
            @details="details"
          ></listTable>
        </div>
      </div>
    </div>
    <!-- 表头设置弹窗 -->
    <div class="dialog">
      <el-dialog
        title="Edit columns"
        :visible.sync="dialogVisible"
        width="627px"
      >
        <div class="diaup">
          <span
            >Select the columns you would like to see for your form
            submissions</span
          >
        </div>
        <div class="diadown">
          <div class="dleft">
            <span>Column options</span>
            <div class="formspan">
              <div
                class="formItem"
                v-for="(item, index) in allSelectList"
                :key="index"
              >
                <el-checkbox v-model="item.checked">{{
                  item.name
                }}</el-checkbox>
              </div>
              <br />
            </div>
          </div>
          <div class="dright">
            <span>SELECTED COLUMNS ({{ selectNumber }})</span>
            <div class="contacts">Contact</div>
            <vuedraggable v-model="allSelectTip" class="tiplist_box">
              <div v-for="(item, index) in allSelectTip" :key="index">
                <div class="other" v-if="item.checked">
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-dragDrop"></use>
                  </svg>
                  {{ item.name
                  }}<i @click="closeTip(item)" class="el-icon-close"></i>
                </div>
              </div>
            </vuedraggable>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">
            <!-- Cancel -->
            {{$t('label.emailtocloudcc.button.back')}}
            </el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >
            <!-- Save -->
            {{$t('label.chatter.save')}}
            </el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 提示 -->
    <el-dialog
      :visible.sync="dialogActionVisible"
      :title="'Archive this page?'"
      :show-close="false"
      top="15%"
      width="40%"
      :close-on-click-modal="false"
    >
      <span class="action_dialog">
        <!-- 确认关闭 -->
        this page will be archive. You can acess it later by selecting
        "Archived" in the left navigation undoer "All landing pages"
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini">
          <!-- changes -->
          Archived
        </el-button>
        <el-button size="mini">
          <!-- drafts -->
          Archived and unpublish
        </el-button>
        <el-button
          size="mini"
          @click="
            () => {
              this.dialogActionVisible = false;
            }
          "
        >
          CanCel
        </el-button>
      </span>
    </el-dialog>
    <!-- create提示 -->
    <el-dialog
      :visible.sync="dialogCreateVisible"
      :title="'Create a page'"
      :show-close="false"
      top="15%"
      width="40%"
      :close-on-click-modal="false"
    >
      <div class="createPage">
        <div style="font-size: 16px; font-weight: bold; word-break: break-word">
          internal page name
        </div>
        <el-input v-model="pageName" class="createInput"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              this.dialogCreateVisible = false;
            }
          "
          size="mini"
        >
          <!-- drafts -->
          <!-- Cancel -->
          {{$t('label.emailtocloudcc.button.back')}}
        </el-button>
        <el-button type="primary" size="mini" @click="toCreate">
          <!-- changes -->
          Create page
        </el-button>
      </span>
    </el-dialog>
    <newSocial ref="newSocial" @addPost="addPost"></newSocial>
    <createSocial ref="createSocial"></createSocial>
  </div>
</template>

<script>
/**
 * 市场云登录统计数据查看
 */
import listTable from "@/views/landingPage/component/listTable.vue";
import vuedraggable from "vuedraggable";
import createSocial from "@/views/social/components/createSocial.vue";
import newSocial from "@/views/social/components/newSocial.vue";
export default {
  components: { listTable, vuedraggable, createSocial, newSocial },
  watch: {
    allSelectList: {
      deep: true,
      handler() {
        let objJson = JSON.stringify(this.allSelectList);
        this.allSelectTip = JSON.parse(objJson);
      },
    },
  },
  created() {
    let objJson = JSON.stringify(this.allSelectList);
    this.allSelectTip = JSON.parse(objJson);
  },
  computed: {
    // 表头个数
    selectNumber() {
      let index = 0;
      this.allSelectTip.forEach((item) => {
        if (item.checked) {
          index++;
        }
      });
      return index;
    },
    // 选择显示文字
    campaignText() {
      let checked = 0;
      this.checkList.forEach((element) => {
        if (element.checked) {
          checked++;
        }
      });
      if (checked === 0) {
        return this.$i18n.t('c625');
      } else {
        return `campaigns(${checked})`;
      }
    },
  },
  data() {
    return {
      pageName: "",
      dialogCreateVisible: false,
      dialogActionVisible: false,
      //拖拽弹框
      allSelectTip: [],
      allSelectList: [
        { name: "Conversion page", checked: false },
        { name: "Submitted on", checked: true },
        { name: "Email", checked: false },
        { name: "Firstname", checked: true },
        { name: "Lastname", checked: false },
        { name: "Company", checked: true },
        { name: "Country", checked: true },
      ],
      drawer: false,
      //表格数据
      checkDisable: false,
      tableAttr: [
        {
          aa: "NAME",
          bb: "name",
          cc: false,
          dd: true,
          ee: "A",
          width: "400px",
        },
        { aa: "EMAIL", bb: "email", cc: false, dd: true, ee: "B", width: "" },
        {
          aa: "PHONE NUMBER",
          bb: "phonenumber",
          cc: false,
          dd: true,
          ee: "B",
          width: "",
        },
        {
          aa: "CREATOR",
          bb: "creator",
          cc: false,
          dd: true,
          ee: "B",
          width: "",
        },
        {
          aa: "PUBLISH STATUS",
          bb: "colorFlg",
          cc: false,
          dd: true,
          ee: "C",
          width: "",
        },
      ],
      tableheight: "420px", //表格高度
      buttonlist: ["Edit", "Clone", "Action"],
      tableData: [
        {
          name: "Asddd",
          vshow: false,
          email: "123456@qq.com",
          phonenumber: "-",
          colorFlg: { color: "#006DCC", value: "123321312" },
          optionshow: false,
        },
        {
          name: "Asddd",
          vshow: false,
          email: "123456@qq.com",
          phonenumber: "-",
          colorFlg: { color: "#006DCC", value: "123321312" },
          optionshow: false,
        },
        {
          name: "Asddd",
          vshow: false,
          email: "123456@qq.com",
          phonenumber: "-",
          colorFlg: { color: "#006DCC", value: "123321312" },
          optionshow: false,
        },
        {
          name: "Asddd",
          vshow: false,
          email: "123456@qq.com",
          phonenumber: "-",
          colorFlg: { color: "#006DCC", value: "123321312" },
          optionshow: false,
        },
        {
          name: "dvgdnhjutm",
          vshow: false,
          email: "ssssss@qq.com",
          phonenumber: "-",
          colorFlg: { color: "#008844", value: "123321312" },
          optionshow: false,
        },
        {
          name: "wwwwww",
          vshow: false,
          email: "09754@qq.com",
          phonenumber: "1359344555455",
          colorFlg: { color: "#006DCC", value: "123321312" },
          optionshow: false,
        },
        {
          name: "wwwwww",
          vshow: false,
          email: "4568789@qq.com",
          phonenumber: "13034456788",
          colorFlg: { color: "#E5851A", value: "123321312" },
          optionshow: false,
        },
      ],
      selectvalue: {
        selectvalue1: ["view", "details", "share"],
        selectvalue2: ["unpublished", "achieve", "delete"],
      },
      pageshow: true,
      dialogVisible: false,
      checkList: [
        { mes: "123", checked: true },
        { mes: "zyt", checked: false },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
        { mes: "cloudcc", checked: true },
      ],
      searchPost: "",
      searchValue: "",
      selectName: "All",
      tipsValue: "",
      domainList: [
        { name: "all do", id: "alldo" },
        { name: "all1 do", id: "alldo2" },
        { name: "all2 do", id: "alldo1" },
      ],
      tipList: [
        { color: "#006DCC", value: "Draft" },
        { color: "#E5851A", value: "Scheduled" },
        { color: "#008844", value: "Published" },
        { color: "#FFFFFF", value: "Archived" },
      ],
    };
  },
  //方法集合
  methods: {
    details(row) {
      this.$router.push({
        name: "landingPageDetail",
        params: {
          id: row.name,
        },
      });
    },
    achieve() {
      this.dialogActionVisible = true;
    },
    unpublished() {
      this.dialogActionVisible = true;
    },
    addPost(imgFlg) {
      this.$refs.createSocial.createSocial();
      this.$refs.newSocial.handleClose();
      this.$refs.createSocial.addPost(imgFlg);
    },
    newSocial() {
      this.$refs.newSocial.newSocial();
    },
    share() {
      this.newSocial();
    },
    toCreate() {
      this.$router.push({
        name: "create",
        params: {
          name: this.pageName,
        },
      });
    },
    // 关闭tips
    closeTip(item) {
      this.allSelectList.forEach((list) => {
        if (list.name === item.name) {
          list.checked = false;
        }
      });
    },
    showDrawer() {
      this.drawer = true;
    },
    searchPostMethod() {},
    searchList() {},
    tipChecked(value) {
      if (this.tipsValue === value) {
        this.tipsValue = "";
      } else {
        this.tipsValue = value;
      }
    },
    handleCommand(item) {
      this.selectName = item.name;
    },
  },
};
</script>
<style lang='scss' scoped>
.icon {
  display: inline-block;
}
.createPage {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  width: 100%;
  .createInput {
    width: 100%;
  }
}
.action_dialog {
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: MicrosoftYaHei;
  color: #080707;
  letter-spacing: 0;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  .diaup {
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
  }
  .diadown {
    margin-top: 20px;
    display: flex;
    .dleft {
      border-right: 1px solid #dedcda;
      width: 50%;
      height: 300px;
      overflow: auto;
      .defaultspanheader,
      .formspan {
        .formItem {
          height: 30px;
        }
        .el-checkbox {
          ::v-deep .is-checked {
            color: red !important;
          }
        }

        margin-bottom: 10px;
        .el-checkbox {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #080707;
          letter-spacing: 0;
        }
      }
      span:nth-child(1),
      span:nth-child(5) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        display: inline-block;
        margin-bottom: 10px;
      }
      span:nth-child(3) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        display: inline-block;
        margin: 10px 0;
      }

      ::v-deep .el-input__inner {
        width: 262px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        text-align: left;
      }
    }
    .dright {
      margin-left: 13px;
      width: 280px;
      .tiplist_box {
        height: 220px;
        overflow: auto;
      }
      .contacts {
        width: 262px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        padding: 8px 0 0 24px;
        margin-bottom: 10px;
      }
      .other {
        cursor: pointer;
        width: 262px;
        height: 40px;
        background: #f8fcff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        padding: 8px 0 0 10px;
        margin-bottom: 10px;
        position: relative;
        .icon {
          margin-right: 10px;
        }
        i {
          position: absolute;
          top: 12px;
          right: 10px;
        }
      }
      span:nth-child(1) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }
}
.manage_title {
  cursor: pointer;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #006dcc;
  letter-spacing: 0;
  display: inline-block;
  margin: auto 10px;
}
.postingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.postingCheck {
  height: 200px;
  overflow: auto;
  img {
    margin: 0px 5px 2px 0px;
    width: 20px;
    height: 20px;
  }
  margin-left: 20px;
}
.bodynav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  .bodynavleft {
    display: flex;
    align-items: center;
    ::v-deep .el-input {
      width: 337px;
      height: 34px;
      float: left;
    }
    .Daterange {
      float: left;
      margin-left: 16px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      width: 250px;
      .el-dropdown-link {
        cursor: pointer;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #006dcc;
        letter-spacing: 0;
      }
    }
  }
}

.top {
  padding: 10px;
  height: 100%;
  width: 100%;
  .content {
    height: 100%;
    width: 100%;
    background-color: #ffffff;
  }
}
.headerTop {
  height: 10%;
  padding: 5px;
  .header_box {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dedcda;
    .mainTitle {
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      color: #080707;
    }
  }
}
.curli {
  background-color: #dedede;
}
.primary {
  height: 90%;
  .aside {
    width: 250px;
    float: left;
    height: 100%;
    padding: 5px 10px;
    .tips_box {
      padding-bottom: 10px;
      border-bottom: 1px solid #dedcda;

      .optionTips:hover {
        background-color: #dedede;
      }
      .optionTips {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 30px;

        .round_img {
          border: 1px solid #dddbda;
          height: 10px;
          width: 10px;
          border-radius: 50%;
        }
        span {
          font-family: SourceHanSansCN-Regular;
          font-size: 12px;
          color: #080707;
        }
      }
    }
    .pages_box {
      padding: 8px 0px 10px 10px;
      height: 35px;
      font-family: PingFangSC-Semibold;
      font-size: 12px;
      color: #080707;
      background: #fafaf9;
      border: 1px solid #e3e2e2;
      border-radius: 3px;
      font-weight: bold;
    }
    .select_box {
      margin: 10px 0px 20px 10px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .select_title {
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      font-size: 12px;
      color: #080707;
    }
  }
  .main-content {
    margin-left: 250px;
    height: 100%;
  }
}
</style>